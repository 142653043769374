import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import MemberFilterMenu from '@uz/unitz-tool-components/MemberFilterMenu';
import B2BAddMemberModal from '@uz/unitz-tool-components/B2BAddMemberModal';
import { Button } from '@uz/unitz-components-web/Button';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import CustomTable from '@uz/unitz-components-web/CustomTable';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="m-4">
          {ctx.debug(() => {
            const roles = ctx.get('roles');
            ctx.set('roleOptions', roles);
          })}
          <MemberFilterMenu />
          <div className="py-6 flex justify-between items-center">
            <div className="text-main font-semibold text-base">{ctx.apply('i18n.t', 'ZoomToolMember.title')}</div>
            <Row>
              <Col span={12}>
                <ModalProvider>
                  <Button
                    type="primary"
                    size="medium"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <B2BAddMemberModal />;
                      });
                    }}
                  >
                    <div className="flex items-center">
                      <div className="text-white500 font-semibold">
                        {ctx.apply('i18n.t', 'ZoomToolMember.add_user_button')}
                      </div>
                      <div>
                        {gstyles.icons({
                          name: 'plus',
                          size: 14,
                          fill: gstyles.colors.white500,
                          className: 'mx-2',
                        })}
                      </div>
                    </div>
                  </Button>
                </ModalProvider>
              </Col>
            </Row>
          </div>

          <CustomTable
            columns={ctx.get('tableColumn.columns')}
            dataSource={ctx.get('tableColumn.dataSource', [])}
            rowKey={(rec) => _.get(rec, 'id')}
            loading={ctx.get('paginationModel.isLoading')}
            pagination={ctx.get('tableColumn.pagination')}
            onChange={ctx.get('paginationModel.onChange')}
            size="middle"
          />
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
